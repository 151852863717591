.CompanyDescriptionSection {
    min-height: 15rem;
    padding: 3rem;
    color: #1e1e1e;
    background-color: #FFC301;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CompanyDescriptionSection p {
    width: 70vw;
}