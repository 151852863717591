/* Titles */
.Article h1 {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    padding-top: 1.5rem;
    line-height: 2rem;
}

.Article h2 {
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 1rem;
    line-height: 2rem;
}

.Article p {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    text-align: justify;
}

.Article .table-of-contents-column {
    background-color: #232323;
    color: #FFC301;
    position: relative;
    min-height: 100vh;
    line-height: 1rem;
}

.Article .TableOfContents {
    line-height: 0.9rem;
}

@media only screen and (max-width: 768px) {
    .Article .table-of-contents-column {
        display: none;
    }

    .Article .image-presentation {
        flex-direction: column;
    }

    .Article p {
        text-align: left;
    }
}

.Article .container {
    width: 80%;
    padding-bottom: 5rem;
    padding-top: 2rem;
}

.Article .anchor {
    display: block;
    position: relative;
    top: -3.5rem;
    visibility: hidden;
}

.Article .img-column {
    display: flex;
    justify-content: center;
}