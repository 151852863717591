.LogoCarousel {
    height: 170px;
    background-color: white;
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.LogoCarousel img {
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(200%);
}

.LogoViewport {
    overflow: hidden;
    width: 700px;
    height: 150px;
    position: relative;
}

.animated {
    animation-name: slide;
    animation-duration: 10s;
    animation-timing-function: linear;
}

.reverse-animated {
    animation-name: reverse-slide;
    animation-duration: 10s;
    animation-timing-function: linear;
}

@keyframes slide {
    from { left: 100%; transform: translateX(0%);}
    to { left: 0%; transform: translateX(-100%);}
}

@keyframes reverse-slide {
    to { left: 100%; transform: translateX(0%);}
    from { left: 0%; transform: translateX(-100%);}
}