.PonukaPristrojov .ponuka {
    width: 60vw;
    color: whitesmoke;
    background-color: #3d3d3d;
    box-shadow: 0px 6px 11px 0px rgba(0,0,0,0.25);
    padding: 1rem;
    height: 15rem;
    display: flex;
    position: relative;
}

.PonukaPristrojov .ponuka .columnGroup {
    flex-grow: 3;
    padding-left: 2rem;
}

.PonukaPristrojov .ponuka h1 {
    font-size: 150%;
}

.PonukaPristrojov .ponuka .morePhotosLine {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

.PonukaPristrojov .ponuka .right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.PonukaPristrojov .ponuka {
    text-align: left;
    overflow: hidden;
}

.PonukaPristrojov .ponuka .details {
    width: 30rem;
}

.PonukaPristrojov .ponuka .description {
    font-style: italic;
    max-height: 100%;
    text-overflow: ellipsis;
    height: 124px;
    white-space: wrap;
    overflow: hidden;
}

.PonukaPristrojov {
    display: flex;
    justify-content: center;
    padding-top: 5rem;
    background-color: #2E2E2E;
    min-height: calc(100vh - 52px);
    padding-bottom: 10rem;
}

.PonukaPristrojov button {
    background-color: #2e2e2e;
    border: 3px solid #FFC301;
    border-radius: 0px;
    color: whitesmoke;
    transition: 0.2s all;
}

.PonukaPristrojov button:hover,
.PonukaPristrojov button:focus {
    background-color: #FFC301;
    border: 3px solid #FFC301;
    box-shadow: 0px 0px 4px 0px #FFC301 !important;
    border-radius: 0px;
    color: #2e2e2e;
    transition: 0.2s all;
}

.PonukaPristrojov button:active,
.PonukaPristrojov button:visited {
    background-color: #cc9c00;
    border: 3px solid #cc9c00;
    box-shadow: 0px 0px 0px 0px #FFC301 !important;
    border-radius: 0px;
    color: #2e2e2e;
    transition: 0.1s all;
}

@media only screen and (max-width: 1200px) {
    .PonukaPristrojov .ponuka {
        flex-direction: column;
        height: auto;
        width: 30rem;
    }

    .PonukaPristrojov .ponuka img {
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2rem;
        margin-top: 1rem;
    }
}