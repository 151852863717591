.TableOfContents {
    padding: 1.5rem;
    padding-top: 1rem;
    font-size: 90%;
    line-height: 90%;
    width: inherit;
    position: fixed;
}

.TableOfContents h1 {
    text-align: center !important;
    font-size: 1.2rem !important;
    font-weight: 600 !important;
    margin-bottom: 1rem !important;
    padding-bottom: 1rem;
}

.TableOfContents li {
    margin-bottom: 1rem;
    list-style: none;
    color: #f1f1f1 !important;
    transition: 0.3s all;
    transition-property: padding-left, border-left;
}

.TableOfContents li:hover {
    color: rgb(255, 255, 255) !important;
    font-weight: 500;
    border-left: 3px solid white;
    padding-left: 5px;
    transition: 0.3s all;
    transition-property: padding-left, border-left;
}