.Kontakt {
    background-color: #2e2e2e;
    display: flex;
    justify-content: center;
}

.KontaktWrapper {
    width: 100vw;
    height: calc(100% - 52px);
    min-height: 100vh;
    background-color: #2e2e2e;
    color: whitesmoke;
}

.KontaktWrapper hr {
    width: 5px;
    margin-left: auto;
    margin-right: auto;
    background-color: rgb(94, 94, 94);
    margin-top: 4rem;
}

@media only screen and (max-width: 1300px)   {
    .KontaktAddr {
        margin-left: 8rem !important;
        margin-right: 8rem !important;
    }
}

@media only screen and (max-width: 770px)   {
    .KontaktAddr {
        width: calc(100vw - 6rem) !important;
        margin-left: 3rem !important;
        margin-right: 3rem !important;
    }
}

.KontaktAddr {
    text-align: left;
    margin-top: 0.5rem;
    width: 50%;
    margin-left: 30rem;
    padding-bottom: 6rem;
    font-size: 130%;
}

.KontaktAddr a {
    color:whitesmoke;
    text-decoration: underline;
}

.Kontakt .form {
    padding-top: 2.5rem;
    width: 80vw;
    text-align: left;
}

.Kontakt .form label {
    color: whitesmoke;
}

.Kontakt input, .Kontakt textarea {
    background-color: #2e2e2e;
    border: 3px solid #FFC301;
    border-radius: 0px;
    color: whitesmoke;
}

.Kontakt input:hover, .Kontakt textarea:hover {
    background-color: #2e2e2e;
    border: 3px solid #ffdb67;
    border-radius: 0px;
    color: whitesmoke;
}

.Kontakt input:focus, .Kontakt textarea:focus {
    background-color: #2e2e2e;
    border: 3px solid white;
    border-radius: 0px;
    color: whitesmoke;
    box-shadow: 0px 0px 17px 0px rgba(255, 255, 255, 0.2) !important;
}

.Kontakt input::placeholder, .Kontakt textarea::placeholder {
    color: rgb(158, 158, 158);
}

.Kontakt .field-label {
    color: whitesmoke;
}

.Kontakt button {
    background-color: #2e2e2e;
    border: 3px solid #FFC301;
    border-radius: 0px;
    color: whitesmoke;
    transition: 0.2s all;
}

.Kontakt button:disabled {
    color: black;
}

.Kontakt .message-body {
    margin-top: 5rem;
}

.Kontakt button:hover {
    background-color: #FFC301;
    border: 3px solid #FFC301;
    box-shadow: 0px 0px 4px 0px #FFC301 !important;
    border-radius: 0px;
    color: #2e2e2e;
    transition: 0.2s all;
}

.Kontakt button:active {
    background-color: #cc9c00;
    border: 3px solid #cc9c00;
    box-shadow: 0px 0px 0px 0px #FFC301 !important;
    border-radius: 0px;
    color: #2e2e2e;
    transition: 0.1s all;
}

.Kontakt .center {
    width: 100%;
    text-align: center;
    color: whitesmoke;
    padding-bottom: 2rem;
}