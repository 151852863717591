.navbar-brand, .navbar-brand:active, .is-black {
    background-color: #232323 !important;
}

.navbar-item {
    color: #FFC301;
    height: 100%;
}

.navbar .highlighted {
    border-bottom: 8px solid #FFC301;
    padding-bottom: 0px;
}

@media only screen and (max-width: 1024px) {
    .navbar .highlighted {
        padding-bottom: 8px;
        border: none;
        background-color: #474029;
    }
}

.navbar-menu {
    background-color: #232323;
}

@media only screen and (min-width: 1024px) {
    .navbar-menu {
        height: 52px;
    }

    .navbar-menu a {
        display: flex;
    }
}

.navbar-item:hover {
    background-color: #FFC301 !important;
    color: #232323 !important;
}