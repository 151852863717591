.TitleSection {
    height: 25rem;
    background-color: #232323;
    color: #FFC301;
}

.TitleSection .utils {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
}

.TitleSection .title {
    color: #FFC301;
    text-shadow: 2px 2px #232323;
    font-weight: 400;
}

.TitleSection .title b {
    font-weight: 600;
}

.TitleSection .langs {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
}

.TitleSection .langs .lang {
    padding: 0.25rem;
    width: 40px;
    height: 40px;
    margin-right: 0.23rem;
    margin-left: 0.23rem;
    border: 4px solid #FFC301;
}

.TitleSection .carousel-desktop {
    padding-top: 3rem;
    width: 100vw;
    display: flex;
    height: 25rem;
    background-position: right;
    background-repeat: no-repeat;
    justify-content: center;
}

.TitleSection .carousel-desktop .carousel-desktop-content {
    width: 50rem;
}

.TitleSection .left-align {
    text-align: left;
}

.TitleSection .horizontal-center {
    display: flex;
    justify-content: center;
    flex-direction: column;
    height: 100%;
}

.sliding {
    animation: sliding 10s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    animation-iteration-count: infinite;
}

@media only screen and (max-width: 960px) {
    .TitleSection .text-column {
        display: none;
    }

    .TitleSection .carousel-desktop-content {
        width: 100vw;
        display: flex;
        justify-content: center;
    }
}

/* Animations */ 

@keyframes sliding {
    0% {
        background-position: right -25px bottom 0px;
    }
    50% {
        background-position: right 0px bottom 0px;
    }
    100% {
        background-position: right -25px bottom 0px;
    }
}