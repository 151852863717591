.order-button {
    font-size: 1.7rem;
    border: 0.5rem solid #a37d00;
    background-color: #FFC301;
    padding: 1.5rem;
    display: inline-block;
    color: #232323;
    transition: all 0.3s;
}

.order-button:hover {
    box-shadow: 0px 0px 24px -1px #FFC301;
    border: 0rem solid #FFC301;
    transform: scale(1.2);
    transition: all 0.3s;
}

.ButtonSection {
    background-color: #232323;
    padding-top: 3rem;
    padding-bottom: 3rem;
    height: 15rem;
}