.App {
  text-align: center;
  font-family: 'Roboto', sans-serif;
}

.App .preload-images img {
  width: 1px;
  height: 1px;
}

.App .preload-images {
  position: absolute;
  width: 1px;
  height: 1px;
}