.MenuSection {
    height: 10rem;
    background-color: #FFC301;
    display: flex;
    justify-content: center;
    align-items: center;
}

.MenuSection a, a:link, a:visited, a:hover, a:active {
    color: #363636;
}

.MenuSection .menu-button {
    margin: 2rem;
    transition: transform 0.3s;
}

.MenuSection .menu-button:hover {
    transform: scale(1.05);
    transition: transform 0.3s;
    cursor: pointer;
}

.MenuSection .menu-button:active {
    transform: scale(0.95);
    transition: transform 0.1s;
    cursor: pointer;
}

.MenuSection .menu-name {
    margin-top: 5px;
    width: 170px;
    height: 50px;
    line-height: 20px;
}

@media only screen and (max-width: 960px) {
    .MenuSection .menu-name {
        height: auto;
    }

    .MenuSection {
        height: auto;
        flex-wrap: wrap;
    }
}